.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 1% auto 1% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 32%;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;
}

.close {
    color: #aaa;
    float: right;
    font-size: 24px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.open-button {
    text-transform: none !important;
    color: #FFFFFF !important;
    background: rgb(36 56 133 / 63%) !important;
    position: absolute !important;
    bottom: 4px;
    left: 4px;
}

.goto-button {
    text-transform: none !important;
    color: #FFFFFF !important;
    background: rgb(36 56 133 / 63%) !important;
    position: absolute !important;
    bottom: 4px;
    right: 4px;
}

.open-button:hover{
    text-transform: none !important;
    color: #000000;
    background: rgb(36 56 133 / 85%) !important;
}

.modal-title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
}

.contentMedia{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    padding: 4px;
    border-radius: 8px;
    max-height: 25rem;
}

.mediaScale{
    max-height: 25rem;
}