body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: sans-serif;
}

div[title="socket"] {
  width: 18px !important;
  height: 18px !important;
}

span[data-testid="control-modal"] {
  display: block;
  padding: 6px 18px;
  position: absolute;
  bottom: 0px;
  left: -11px;
}

div[data-testid="context-menu"]{
  width: 14em;
}

.rete>div>div[draggable="true"]{
  transform: none !important;
  vertical-align: top;
  margin: 0px !important;
  padding: 0em 1em !important;
  height: fit-content !important;
  overflow: hidden;
  transform-origin: top left !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5rem !important;
}

.rm_margin_content{
  margin: 0px !important;
}

.rt_content_cases{
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 1rem;
  border: 1px solid #efefefa8;
  border-radius: 8px;
  box-shadow: 1px 1px 8px -4px rgba(0,0,0,0.75);

  .Mui-focusVisible{
    background: none !important;
  }
}

.interactive-modal-container{
  .container-buttons-and-list-preview{
    .preview-container{
      .message-content{
        .msgContent{
          margin: 0px;
          padding: 0px;

          p{
            font-size: 0.9rem;
            color: #444;
            margin: 0px;
            padding: 0px;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
}

.rt_cases_accordion{
  background-color: aqua;
}

.time_range_content{
  border: 1px solid #cccccc59;
  border-radius: 8px;
  padding: 0 1rem;
  margin: 8px;
  margin-bottom: 12px;
}

.time_range_title{
  display: flex;
  align-items: center;
}

.icon_title_time{
  margin-right: 4px;
  margin-top: 3px;
}

.set_vars_content{
  border: 1px solid #cccccc59;
  border-radius: 8px;
  padding: 0 1rem;
  margin: 8px;
  margin-bottom: 12px;
}

.node-start{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px !important;
}

.label-name-start{
  padding: 0px 12px 0px 0px;
}

#scroll-dialog-description{
  white-space: pre-line;
}

.constextMenuCustomItem{
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.marginRightIcons{
  margin-right: 8px;
}

.text-editor-rich-wrapper{
  border: 1px solid #F1F1F1;
  border-radius: 8px;
  margin: 0px 6px;
  margin-bottom: 1rem;

  .rdw-dropdown-wrapper{
    .rdw-dropdown-optionwrapper{
        max-height: 150px;
    }
  }
}

.public-DraftStyleDefault-ltr{
  padding: 0px 6px;
  color: #000000;
}

.public-DraftStyleDefault-block{
  margin: 0px !important;
}

.public-DraftEditor-content{
  height: 240px !important;
}

.rdw-editor-toolbar button{
  background-color: transparent;
  border: none;
  height: 2rem;
  border: 1px solid #F1F1F1;
}
.rdw-editor-toolbar button:hover{
  background-color: transparent;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.interactiveOptions, .interactiveItems{
    padding: .5rem;
}

.add-interactive-option,
.remove-interactive-option,
.remove-variable-option,
.round-button,
.remove-case,
.expand-interactive-option,
.expand-case-option,
.box-button-style{
  button{
    border: 1px solid #aaa7;
    width: 24px;
    height: 24px;
  }
}

.section-list-container{
  background: #efefef78;
  border-radius: 8px;
  margin: 10px 0px;
  padding: 10px;

  .Mui-focusVisible{
    background: none !important;
  }
}

.section-container{
  border: 1px solid #efefefa8;
  border-radius: 8px;
  padding: 20px 10px;
  box-shadow: 1px 1px 8px -4px rgba(0,0,0,0.75);
  margin-bottom: 20px;
}

.cases-container{
  border: 1px solid #efefefa8;
  border-radius: 8px;
  padding: 20px 10px;
  box-shadow: 1px 1px 8px -4px rgba(0,0,0,0.75);
  margin: 20px 8px;
  width: 95.5% !important;
}

.section-list-option-container{
  border: 1px solid #ccc;
  border-radius: 8px;
}

.container-buttons-and-list-preview{
  background: url('../public/whatsapp_wallpaper.jpg');
  background-repeat: no-repeat;
  border-radius: 8px;
  height: 100%;
  padding: 5px 0px;
  overflow-y: scroll;
}

.document-preview{
  border-radius: 8px;
  height: 50px;
  padding: 5px 0px;
  text-align: center;
  margin-bottom: 5px;
}

.preview-container{
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  min-height: 50px;
}

.button-container, .list-container{
  padding-left: 0 !important;
}

.button-preview{
  background: #fff !important;
  color: #000 !important;
  border-radius: 10px;
  padding: 3px;
  cursor: pointer;
  text-align: center;
}

.list-sections-container{
  border-radius: 8px;
  padding: 10px;
  background: #fff;
}

.container-list-preview{
  overflow: hidden;
}

.slide-div {
  position: relative;
  bottom: -120rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  height: 200px;
  background: #fff;
  border-radius: 10px;
  transition: bottom 0.8s ease-in-out;
}

.slide-div.visible {
  bottom: 0%;
}

.moduleIconButton{
  border-radius: 50% !important;
  height: 26px !important;
  width: 26px !important;
}

.moduleTabButton{
  white-space: nowrap !important;
  min-width: auto !important;

  &:focus{
    background-color: #68aeff !important;
  }
}

.selectedModuleTabButton{
    background-color: rgba(0, 0, 0, 0.04) !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width:10px;
}

::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

.jinjaContainer{
  border: 1px solid #aaaa;
  border-radius: 4px;
  padding: 4px 5px !important;


  .jinjaEditor{
    padding: 0px;
    margin: 0px !important;
    .cm-focused{
      outline: none !important;
    }
  }
}

.jinjaLabelContainer{
  margin: 0px !important;
  margin-bottom: 5px;
}

.dropdown {
  position: relative;
}

.dropdownIcon {
  float: right;
  margin-left: auto;
}

.dropdownContent {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.time_picker_style > .MuiInputBase-root{
  height: 40px;
}
.time_picker_style > .MuiFormLabel-root {
  top: -6px
}

.variables-container{
  border: 1px solid #efefefa8;
  border-radius: 8px;
  box-shadow: 1px 1px 8px -4px rgba(0,0,0,0.75);
  margin-bottom: 20px;
}
