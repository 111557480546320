.custom-input {
    background-color: #f8f8f8;
    border: 1px solid #d9d9d9;
    width: 80%;
    border-radius: 20px;
    padding: 3px 8px;
    font-size: 16px;
    color: #333;
    margin: 5px 8px;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}